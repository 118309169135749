@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

body {
  font-family: 'Nunito', sans-serif;
}

.header {
  font-family: 'Varela Round', sans-serif;
}


:root {
  --bg-color: #f4f4f4;
  --text-color: #000000;
  --container-bg-color: #ffffff;
  --button-bg-color: #333;
  --button-bg-color-hover: #777;
  --border-color: #ccc;
  --input-bg-color: #fff;
  --box-shadow-color: rgba(0,0,0,0.1);
  --artwork-shadow-color: rgba(0, 0, 0, 0.3);
  --infoBox-bg-color: rgba(255, 255, 255, 0.8);
  --infoBox-text-color: #000000;
  --linkColor: rgba(0, 25, 248, 0.8);
}

[data-theme='dark'] {
  --bg-color: #121212;
  --text-color: #ffffff;
  --container-bg-color: #2a2a2a;
  --button-bg-color: #BB86FC;
  --button-bg-color-hover: #9e55d8;
  --border-color: #444;
  --input-bg-color: #333;
  --box-shadow-color: rgba(255, 255, 255, 0.1);
  --artwork-shadow-color: rgba(255, 255, 255, 0.2);
  --infoBox-text-color: #000000;
  --linkColor: #c695e5;
  --search-input-color: #fff;
  --search-input-placeholder-color: #ccc;
}

