.footer {
    background-color: #ededed;
    padding: 10px;
    text-align: center;
    height: 200px;
    width: 100%;
    position: fixed;
    bottom: 50px;
}

.footer-container {
    font-size: 14px;
    margin-top: 5px;
    font-family: 'Nunito', sans-serif;
    position: fixed;
}

.container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    font-family: 'Nunito', sans-serif;
}
  
.left-column,
.right-column {
    flex: 1;
    flex-grow: 1;
}

.help-section {
    column-gap: 10px;
    column-count: 5;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 13px;
    font-family: 'Nunito', sans-serif;

}

.help-section h4,
.help-section p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 15px;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
  }

.help-section a {
    text-decoration: none;
}

.help-section a:hover,
.help-section a:focus {
    color: #555;
    text-decoration: underline;
}
  
.middle-column {
    flex: 2;
    flex-direction: column;
    align-items: center;
}

.newsletter {
    color: #000;
    font-size: 14px;
}

.newsletter-text {
    color: #000;
    font-size: 15px;
    text-align: center!important;
    margin-top: 15px;
    font-weight: 100;
    font-size: 14px;
    font-weight: 600;
}

.subscribe-area {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    margin-top: 10px;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
}

.subscribe-bar {
    flex-grow: 2;
    width: 100%;
    max-width: 475px;
    padding: 10px;
    border: 5px;
    border-radius: 6px;
    border-color: #888;
    color: #888;
    font-size: 14px;
}

.subscribe-btn {
    padding: 11px 13px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 800;
    cursor: pointer;
}

.subscribe-btn:hover {
    background-color: #333;
}

.social-media {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-media i {
    font-size: 13px;
    cursor: pointer;
  }

  .social-media i:hover {
    color: #555;
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding-top: 13px;
    background-color: #5d6f78;
    border-top: 1px solid #333;
    color: #fff;
    height: 50px;
    font-size: 13px;
    font-family: 'Nunito', sans-serif;
    width: 100%;
    position: fixed;
    bottom: 0;
}
  
  .left-section,
  .right-section {
    flex: 1;
    padding: 0px;
    margin-left: 50px;
  }
  
  .left-section {
    padding-right: 10px;
    text-align: left;
  }
  
  .right-section {
    padding-left: 10px;
    text-align: right;
    padding-right: 50px;

  }

  .payment-list__item {
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
  }