.new-outcome-container {
    padding: 20px;
    max-width: 400px;
    margin: auto;
    height: 70vh;
    background-color: #f9f9f9; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.suggestions-container {
    position: absolute;
    z-index: 10; 
    background-color: white; 
    border: 1px solid #ddd; 
    border-top: none;
    width: calc(100% - 20px); 
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    top: 100%;
    left: 10px;
}

.new-outcome-container {
    padding: 20px;
    width: 90%;
    max-width: 400px;
    height: auto;
    min-height: 50vh;
    background-color: #f9f9f9; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s, height 0.3s;
    overflow-y: auto;
}

.suggestion {
    padding: 10px;
    cursor: pointer;
}

.suggestion:hover {
    background-color: #f8f8f8;
}
.new-outcome-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-outcome-form .input,
.new-outcome-form #submit-button {
    width: 100%;
    margin-bottom: 15px;
}

.new-outcome-form #submit-button {
    align-self: center;
    max-width: 100px;
    background-color: #5d6f78;
    color: #fff;
    font-size: 16px;
    margin-top: 30px;    
}

.new-outcome-form #submit-button:hover {
    background-color: #6f8590;
    color: #fff;
}

#submit-btn {
    background-color: #0060b9;
    color: #fff;
    font-size: 16px;
    margin-top: 30px;    
}

#cancel-btn {
    background-color: #D3D3D3;
    color: #fff;
    font-size: 16px;
    margin-top: 30px;    
}