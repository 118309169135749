.navbar2 {
    background-color: #4a5860;
    color: #000;
    padding: 7px;
    margin-bottom: 10px;
}

.submenu {
    background-color: #ffffff;
    color: #000000;
}
