.navbar {
    background-color: #5d6f78;
    color: #ffffff;
}

.p-2 submenu {
    background-color: #ffffff;
    color: #000000;
}

.navbar-center {
    z-index: 9999;
}   
